// loadScript function

jQuery.loadScript = function (url, callback) {
  jQuery.ajax({
    url: url,
    dataType: 'script',
    success: callback,
    async: true
  });
};

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var ThreeSixty = {
    // All pages
    'common': {
      init: function () {

        var getLastPartOfUrl = function ($url) {
          var url = $url;
          var urlsplit = url.split("/");
          var lastpart = urlsplit[urlsplit.length - 1];
          if (lastpart === '') {
            lastpart = urlsplit[urlsplit.length - 2];
          }
          return lastpart;
        };

        $('body').scrollspy({
          target: '.navbar-collapse'
        });

        $(".navbar-collapse li a[href^='#']").on('click', function (event) {
          var target;
          target = this.hash;

          event.preventDefault();

          return $('html, body').animate({
            scrollTop: $(this.hash).offset().top
          }, 300, function () {
            return window.history.pushState(null, null, target);
          });
        });

        var $carousels = $('.carousel').removeClass('is-hidden').flickity({
          contain: true,
          wrapAround: true,
          prevNextButtons: false,
          cellAlign: 'left',
          autoPlay: 4000,
          setGallerySize: false,
          imagesLoaded: true,
          pageDots: true
        });

// trigger redraw for transition
        $carousels[0].offsetHeight;
// init Flickity
        $carousels.flickity();

        if (typeof google === 'undefined') {
          $.loadScript('https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCD__4X_2C6gHCOweFYGnSMP0qxPFS8IOg', function () {

            var $marker = $('#map').find('.marker');

            var map = new GMaps({
              zoom: 16,
              disableDefaultUI: true,
              scrollwheel: false,
              div: '#map',
              lat: $marker.attr('data-lat'),
              lng: $marker.attr('data-lng')
            });

            map.addMarker({
              lat: $marker.attr('data-lat'),
              lng: $marker.attr('data-lng'),
              title: '360 Residential',
              animation: google.maps.Animation.DROP,
              click: function () {
                window.open($marker.attr('data-url'), '_blank');
              }
            });

          });
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = ThreeSixty;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
